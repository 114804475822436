import React, { useEffect, useState } from 'react';
import classes from "./LatestTarget.module.css";
import * as routeConstants from '../../../common/RouteConstants';
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from '@mui/icons-material/Close';
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import { RA_REPORT_DOWNLOAD } from "../../../config/index";
import { GET_LATEST_TARGET } from '../../../graphql/mutations/AdminUI';
import * as msgConstant from "../../../common/MessageConstants";
import { sentry_error_catch } from '../../../common/sentry_error_catch';
import PublishIcon from "@mui/icons-material/Publish";
import { PUBLISH_REPORT } from '../../../graphql/mutations/PublishReport';

const LatestTarget: React.FC = (props:any) => {
  const history = useNavigate();
  const session = Cookies.getJSON("ob_session");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const contentType = "application/json";
  const [newData, setNewData] = useState<any>([]);
  const [token, setToken] = useState<any>("");
  const location: any = useLocation();
  const [targetFilter,setTargetFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [error,setError] = useState<any>(false);
  const [startDate,setStartDate] = useState<any>("");
  const [endDate,setEndDate] = useState<any>("");
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })
  const [getLatestTarget,{data:latestTarget}] = useMutation(GET_LATEST_TARGET, {
    onCompleted: (data:any) => {
      setShowBackDrop(false);
      createTableDataObject(data.PartnerStatsAdminPage.targetField)
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })

  const [publishReport] = useMutation(PUBLISH_REPORT);

  const headerObj = {
    "Content-Type": contentType,
    Authorization: token,
  }
  useEffect(() => {
    setShowBackDrop(true);
    getLatestTarget({
      variables: {
        token: session,
        sort: "id:desc"
      }
    })
  }, [])

  // array to display the column.
  const column: any = [
    {title: "Partner Name", key: "partner_name"},
    { title: "Target Name", key: "target_name" },
    { title: "Client Name", key: "client_name" },
    { title: "Scan Type", key: "scan_type" },
    {title: "Host", key:"host"},
    { title: "Scan Scheduled", key: "scan_scheduled" },
    { title: "Report Generated", key: "report_generated_date" },
    { title: "Status", key: "scan_status" },
    { title: "Delete Status", key: "delete_status" },
    {
      key: "Download",
      title: "",
      icon: <GetAppIcon className={classes.iconStyle} />,
      tooltip: "Download",
      displayIcon: (rowData: any) => {
        return rowData.published_flag === "Published" && rowData.report_generated_date !== "";
      },
    },
    {
      key: "Publish Target",
      title: "",
      icon: (
        <Button className={classes.publishButton}>
          Published
        </Button>
      ),
      tooltip: "Publish Target",
      displayIcon: (rowData: any) => {
        return rowData.scan_status === "In Progress" && rowData.mail_send_flag === "Review";
      },
    },
    {
      key: "View Target Task",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View Sub Target  Details",
      displayIcon: (rowData: any) => true,
    },
  ];
  //this effect gets called after every initial render. 

  // function to handel click event to action button 
  const onRowClick:any = (rowData: any, val: string) => {
    if(val === "Download"){
      onDownload(rowData);
    }else if(val === "View Target Task"){
      history(routeConstants.ADMIN_SUB_TARGET_DETAILS, {state: {rowData,page:"LatestTarget"}});
    }else if(val === "Publish Target"){
      publishClientReport(rowData)
    }
  }
  const onDownload = async (rowData: any) => {
    handleAlertClose();
    if (Cookies.getJSON("ob_session")) {
      setShowBackDrop(true);
      let intTargetId = parseInt(rowData.id);
      const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${parseInt(rowData.client_id)}&tid=${intTargetId}`;
      fetch(DocUrl, {
        method: "GET",
      })
        .then((response: any) => {
          response.blob().then((blobData: any) => {
            saveAs(blobData, rowData.target_name.replace(/[^a-zA-Z0-9]/g, ""));
            setShowBackDrop(false);
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } else {
      logout();
    }
  };

  const publishClientReport = (rowData: any) => {
    console.log(rowData)
    setShowBackDrop(true);
    publishReport({
      variables: {
        input: {
          client: parseInt(rowData.client_id),
          id: rowData.id,
          targetName: rowData.target_name,
          flagStatus: true,
        },
      },
    })
      .then((response: any) => {
        setShowBackDrop(false);
        console.log(response)
        if (
          response.data.publishedReport.success ==
          "Report Published Successfully "
        ) {
          setShowBackDrop(true);
          getLatestTarget({
            variables: {
              token: session,
              sort: "id:desc"
            }
          }).then(()=>{
            setShowBackDrop(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: "Report Published Successfully !!",
            }));
          }).catch((err)=>{
             sentry_error_catch(err,setShowBackDrop,setFormState)
          })
        } else {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: " Report Un-Published Successfully !!",
          }));
        }
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      });
  };
  // data object creation to display in table
  const createTableDataObject = async (data: any,range?:boolean) => {
    let arr: any = [];
    let startDateOfWeekFromNow = moment().subtract(7, 'days').calendar();
    const weekDate = new Date(startDateOfWeekFromNow);
    if(range){
      setShowBackDrop(false);
    }
    if (data && data.length !== 0) {
      if (data !== 0) {
        data.forEach((element: any, i: any) => {
          if(new Date(element.startDate) >= weekDate || range){
            if(element.deletedStatus !== "True" ){
            const obj: any = {};
            obj["id"] = element.id;
            obj["delete_status"] = element.deletedStatus === "True" ? "Deleted" : "Not Deleted" ;
            obj["client_name"] = element.clientName ? element.clientName : "";
            obj["scan_scheduled"] = moment(element.startDate).format(timeFormat );
            obj["partner_name"] = element.partnerName ? element.partnerName : "";
            obj["report_generated_date"] = element.reportCreationDate ? moment(element.reportCreationDate).format(timeFormat ): "";
            obj["published_flag"] = element.publishedFlag ? element.publishedFlag : "";
            obj["client_id"] = element.clientId ? element.clientId : "";
            obj['scan_status'] = element.targetStatus ? element.targetStatus : "";
            obj['scan_type'] = element.scanType ? element.scanType : "";
            obj['target_name'] = element.targetName ? element.targetName : "";
            obj['host'] = element.host ? element.host : "";
            obj['mail_send_flag'] = element.mailSendFlag ? element.mailSendFlag : "";
            arr.push(obj);
            }
          }
        });
      }
    }
    if(!range){
      setNewData(arr);
    }
    setSearchData(arr);
  }

  // to handle delete event.
  
 
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
  }
  
  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  const handleTargetFilterChange = (event:any) => {
    if(event.target.name === "endDate"){
      setEndDate(event.target.value)
    }else if(event.target.name === "startDate"){
      setStartDate(event.target.value)
    }else{
      setTargetFilter(event.target.value)
    }
   }
   const searchResetHandler = (event:any) => {
     setTargetFilter("")
     setStartDate("")
     setEndDate("")
     handleAlertClose();
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
    handleAlertClose();
    if(targetFilter.trim().length > 0){
       const search:any = []
       latestTarget.PartnerStatsAdminPage.targetField.forEach((element:any) => {
        //  console.log(element.targetName,targetFilter)
         if(element.targetName && element.targetName.includes(targetFilter)){
           const obj: any = {};
            obj["id"] = element.id;
            obj["delete_status"] = element.deletedStatus === "True" ? "Deleted" : "Not Deleted" ;
            obj["client_name"] = element.clientName ? element.clientName : "";
            obj["scan_scheduled"] = moment(element.startDate).format(timeFormat );
            obj["partner_name"] = element.partnerName ? element.partnerName : "";
            obj["report_generated_date"] = element.reportCreationDate ? moment(element.reportCreationDate).format(timeFormat ): "";
            obj["published_flag"] = element.publishedFlag ? element.publishedFlag : "";
            obj["client_id"] = element.clientId ? element.clientId : "";
            obj['scan_status'] = element.targetStatus ? element.targetStatus : "";
            obj['scan_type'] = element.scanType ? element.scanType : "";
            obj['target_name'] = element.targetName ? element.targetName : "";
            obj['host'] = element.host ? element.host : "";
            obj['mail_send_flag'] = element.mailSendFlag ? element.mailSendFlag : "";
            search.push(obj);
         }
       });
       setSearchData(search)
    }else if(endDate !== "" && startDate !== "" && targetFilter === ""){
      if(startDate > endDate){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_DETAIL_DATE_ERR,
        }))
      }else{
        setShowBackDrop(true);
        const rangeData = latestTarget.PartnerStatsAdminPage.targetField.filter((element:any) => {
          if(element.startDate >= startDate && element.startDate <= endDate){
            return element
          }
        });
        createTableDataObject(rangeData,true)
      }
    }else if(startDate === ""){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: msgConstant.SELECT_START_DATE,
       }))
    }else if(endDate === ""){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: msgConstant.SELECT_END_DATE,
       }))
    }else if(targetFilter === ""){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: msgConstant.SEARCH_TARGET_NAME_EMPTY,
       }))
    }
   }
   
  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <Typography component="h5" variant="h1">
      Latest Targets
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={10} className={classes.FilterWrap}>
        <span style={{ width: "230px" }}>
          <Grid item md={12} xs={12}>
            <Input
              // required={true}
              id="target-id"
              type="search"
              name="filterTarget"
              label="Search Target Name"
              variant="outlined"
              value={targetFilter}
              onChange={handleTargetFilterChange}
              error
            />
          </Grid>
        </span>
        <div style={{ width: "180px",marginLeft:"10px" }} className={classes.FilterDateInput}>
          <Input
            // required={true}
            id="start-date"
            type="date"
            name="startDate"
            label="Start Date"
            variant="outlined"
            value={startDate}
            onChange={handleTargetFilterChange}
          />
        </div>
        <div style={{ width: "180px", marginLeft:"10px" }}>
          <Input
            // required={true}
            id="end-date"
            type="date"
            name="endDate"
            label="End Date"
            variant="outlined"
            value={endDate}
            onChange={handleTargetFilterChange}
          />
        </div>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
      {formState.isSuccess ? (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
           />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Latests Target Available.
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default LatestTarget;
